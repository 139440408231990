var _a;
import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
var generateInitialState = function () {
    return {
        isLoading: false,
        isAuthed: false,
        id_token: undefined,
        isExpired: false,
        authError: undefined,
    };
};
var initialState = generateInitialState();
export var authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        expired: function (state) {
            state.isAuthed = false;
            state.isExpired = true;
        },
        loading: function (state) {
            state.isLoading = true;
        },
        auth_error: function (state, action) {
            state.isAuthed = false;
            state.authError = action.payload;
        },
        loaded: function (state) {
            state.isLoading = false;
        },
        logged_out: function () {
            localStorage.removeItem('access_token');
            return generateInitialState();
        },
        token_updated: function (state, action) {
            _.merge(state, action.payload);
            state.isAuthed = true;
            state.isLoading = false;
            state.authError = undefined;
            localStorage.setItem('access_token', action.payload.access_token);
        },
    },
});
export var expired = (_a = authSlice.actions, _a.expired), logged_out = _a.logged_out, token_updated = _a.token_updated, loaded = _a.loaded, loading = _a.loading, auth_error = _a.auth_error;
export var selectIsLoading = function (state) {
    return state.auth.isLoading;
};
export var selectIsAuthed = function (state) {
    return state.auth.isAuthed;
};
export var selectAccessToken = function (state) { return state.auth.access_token; };
export var selectIdToken = function (state) {
    return state.auth.id_token;
};
export var selectRefreshToken = function (state) { return state.auth.refresh_token; };
export var selectAccessTokenExpire = function (state) { return state.auth.access_token_expire; };
export var selectRefreshTokenExpire = function (state) { return state.auth.refresh_token_expire; };
export var selectAuthError = function (state) { return state.auth.authError; };
export default authSlice.reducer;
