import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useAuth } from './useAuth';
import { useEffect, useState } from 'react';
export var AuthCallback = function (_a) {
    var history = _a.history, children = _a.children, onError = _a.onError, _b = _a.defaultRedirect, defaultRedirect = _b === void 0 ? '/' : _b, _c = _a.isPopup, isPopup = _c === void 0 ? false : _c, _d = _a.isSilent, isSilent = _d === void 0 ? false : _d;
    var auth = useAuth();
    var _e = useState(false), showError = _e[0], setShowError = _e[1];
    useEffect(function () {
        if (!auth.isLoading) {
            auth
                .signinCallback()
                .then(function () {
                if (!isPopup && !isSilent) {
                    var redirectUri = sessionStorage.getItem('redirect-uri');
                    if (redirectUri) {
                        history.push(redirectUri);
                    }
                    else {
                        history.push(defaultRedirect);
                    }
                }
            })
                .catch(function (e) {
                console.error(e);
                setShowError(true);
            });
        }
    }, [isPopup, auth]);
    if (showError && onError !== undefined) {
        return _jsx(_Fragment, { children: onError() });
    }
    return _jsx(_Fragment, { children: children });
};
